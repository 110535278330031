import React from 'react';
import chatbot from '../icons/be_chatbot.png';

export default function Chatmessage({ message }) {

    const messageText = message && message.text ? message.text : '';
    return (
      <div className={`message ${message.role === "model" ? 'bot' : 'user'}-message`}>
        {message.role === "model" && <img className='chat' src={chatbot} alt='byteedge' />}
        <p className='message-text' dangerouslySetInnerHTML={{ __html: messageText }}></p>
      </div>
    );
  }
