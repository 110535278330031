import React,{useRef} from 'react'

export default function Chatform({chatHistory, setChatHistory, generateBotResponse}) {
    const inputRef= useRef();

    const handleFormSubmit =(e)=>{
        e.preventDefault();
        const userMessage = inputRef.current.value.trim();
        if(!userMessage) return;
        inputRef.current.value = "";
        // updated chat history with the user's message
        setChatHistory((history) => [...(history), {role: "user", text: userMessage}]);

        setTimeout(() => {
            setChatHistory((history) => [...(history), {role: "model", text: '...'}]);
            
            generateBotResponse([...chatHistory , {role: "user", text: userMessage}]);
        }, 600);

    }
    return (
        <>
            <form action='#' className='chat-form' onSubmit={handleFormSubmit}>
                <input ref={inputRef} type='text' placeholder='message...' className='message-input' required />
                <button className="material-symbols-outlined">send</button>
            </form>
        </>
    )
}
