import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import chatbot from "../icons/be_chatbot.png";
import Chatform from "./Chatform";
import Chatmessage from "./Chatmessage"; // Import the Chatmessage component
import axios from "axios";
// import { error } from 'jquery'

export default function Chatbot() {
  const [chatHistory, setChatHistory] = useState([]);
  const [showChatbot, setShowChatbot] = useState(false);
  const [conversationId, setConversationId] = useState("");
  const [userId, setUserId] = useState("");
  const [messageId, setMessageId] = useState(""); // Add state for message_id
  const chatBodyRef = useRef();
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const inactivityTimeoutRef = useRef(null);
  const inactivityCalledRef = useRef(false); // Flag to track if inactivity API call has been made
  const userInteractedRef = useRef(false); // Flag to track if user has interacted with the chatbot
  const email = "experience@byteedge.com"; // Set the email address
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  useEffect(() => {
    // Generate a unique user ID when the component mounts
    setUserId(uuidv4());
  }, []);

  useEffect(() => {
    if (messageId && userId ) {
      // Fetch suggested questions from API
      axios
        .get(
          `https://llm.byteedge.com/v1/messages/${messageId}/suggested?user=${userId}`,
          {
            headers: {
              Authorization: "Bearer app-7jawyeR03OgOJGnwqWmJ2hzA",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.result === "success") {
            setSuggestedQuestions(response.data.data);
          } else {
            console.error("Error fetching suggested questions:", response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching suggested questions:", error);
        });
    }
  }, [messageId, userId]); // Add messageId and userId as dependencies

  const handleQuestionClick = (question) => {
    setSelectedQuestion(question);
    // Add the question to chat history
    setChatHistory([
      ...chatHistory,
      { role: "user", text: question },
      { role: "model", text: "..." },
    ]);
    // Generate bot response
    generateBotResponse([{ role: "user", text: question }]);
  };

  const sendMessage = (message) => {
    // Function to send the selected question to the bot
    // Update chat history with the user's message
    setChatHistory([
      ...chatHistory,

      { role: "user", text: message },

      { role: "model", text: "..." },
    ]);

    // Call generateBotResponse to get the bot's response
    generateBotResponse([...chatHistory, { role: "user", text: message }]);
  };

  const makeLinksClickable = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(
      urlRegex,
      (url) => `<a href="${url}" target="_blank">${url}</a>`
    );
  };

  const handleRefreshChat = async () => {
    setChatHistory([]);

    setSuggestedQuestions([]); // Clear suggested questions

    await sendChatEmail();
  };


  const handleInactivity = async () => {
    // Check if the inactivity API call has already been made or if the user has not interacted
    if (inactivityCalledRef.current || !userInteractedRef.current) return;
  
    // Mark that the inactivity API call has been made
    inactivityCalledRef.current = true;
  
    // Send the chat email
    await sendChatEmail();
  
    // Update the chat history with a message indicating the chat has ended
    updateHistoryOnce(
      "This chat has ended due to inactivity. Please refresh the page and start a new chat."
    );
  
    // Clear suggested questions when the chat ends
    setSuggestedQuestions([]);
  };
  
  // Reset inactivity timeout whenever the user interacts
  const resetInactivityTimeout = () => {
    clearTimeout(inactivityTimeoutRef.current); // Clear any existing timeout
    inactivityCalledRef.current = false; // Reset the inactivity call flag
  
    // Start a new timer for 2 minutes
    inactivityTimeoutRef.current = setTimeout(() => {
      handleInactivity(); // Trigger inactivity handling after the timeout
    }, 2 * 60 * 1000); // 2 minutes in milliseconds
  };
  
  // Add event listeners for user interactions
  useEffect(() => {
    const handleUserInteraction = () => {
      userInteractedRef.current = true; // Mark that the user has interacted
      resetInactivityTimeout(); // Reset the inactivity timer
    };
  
    // Add event listeners for user interaction
    window.addEventListener("mousemove", handleUserInteraction);
    window.addEventListener("keypress", handleUserInteraction);
  
    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("mousemove", handleUserInteraction);
      window.removeEventListener("keypress", handleUserInteraction);
    };
  }, []);
  

  const sendChatEmail = async () => {

    const currentUserId = userId || uuidv4(); // Use the existing or generate a new userId
    const currentConversationId = conversationId || uuidv4(); // Use the existing or generate a new conversationId

    if (!userId) {
      setUserId(currentUserId); // Set the userId if it was generated
    }

    if (!conversationId) {
      setConversationId(currentConversationId); // Set the conversationId if it was generated
    }

    try {
      const response = await fetch(
        "https://lms.byteedgebiz.com/api/send-chat-email",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer app-7jawyeR03OgOJGnwqWmJ2hzA",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: currentUserId,
            conversation_id: currentConversationId,
            email: email,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
    } catch (error) {
      console.error("Error sending chat email:", error);
    }
  };

  const updateHistory = (text) => {
    setChatHistory((prev) => {
      const newHistory = [
        ...prev.filter((msg) => msg.text !== "..."),
        { role: "model", text: makeLinksClickable(text) }, // Make links clickable
      ];
      return newHistory;
    });
  };

  const updateHistoryOnce = (text) => {
    setChatHistory((prev) => {
      const newHistory = [
        ...prev.filter((msg) => msg.text !== "..."),
        { role: "model", text: makeLinksClickable(text) }, // Make links clickable
      ];
      return newHistory;
    });
  };

  const generateBotResponse = async (history) => {
    userInteractedRef.current = true; // Set the flag to true when user interacts with the chatbot

    const requestBody = {
      query: history[history.length - 1].text, // Send only the user's latest input
      inputs: {},
      response_mode: "streaming",
      user: userId, // Use the generated user ID
      conversation_id: conversationId || undefined, // Include conversation ID only if it exists
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer app-7jawyeR03OgOJGnwqWmJ2hzA",
      },
      body: JSON.stringify(requestBody),
    };

    try {
      // Make an API call to get the bot response
      const response = await fetch(
        "https://llm.byteedge.com/v1/chat-messages",
        requestOptions
      );

      if (!response.ok) {
        const data = await response.json();
        console.error("Error response from API:", data);
        if (
          data.code === "not_found" &&
          data.message === "Conversation Not Exists."
        ) {
          updateHistoryOnce(
            "This chat has ended. Please refresh the page and start a new chat."
          );
          setSuggestedQuestions([]); // Hide suggestions when chat ends
        } else {
          throw new Error(data.error.message || "something went wrong!");
        }

        return;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let result = "";
      let accumulatedText = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        result += decoder.decode(value, { stream: true });
        const chunks = result.split("\n\n");
        result = chunks.pop(); // Keep the last incomplete chunk for the next iteration

        for (let chunk of chunks) {
          if (chunk.startsWith("data: ")) {
            try {
              const data = JSON.parse(chunk.slice(6));
              if (data.answer) {
                accumulatedText += data.answer;
              }
              if (data.conversation_id) {
                setConversationId(data.conversation_id); // Update the conversation ID once available
              }
              if (data.message_id) {
                setMessageId(data.message_id); // Update the message ID once available
              }
              if (data.suggested_questions) {
                setSuggestedQuestions(data.suggested_questions); // Update suggested questions
              }
            } catch (e) {
              console.error("Error parsing chunk:", chunk, e);
            }
          }
        }
      }

      // Only update history with the final accumulated response
      updateHistory(accumulatedText.trim());
      setSelectedQuestion(null); // Reset selected question to show new suggestions
    } catch (error) {
      console.error("Error fetching response:", error.message);
      updateHistory("Sorry, something went wrong.");
    }
  };

  useEffect(() => {
    //auto-scroll when chat history updates
    chatBodyRef.current.scrollTo({
      top: chatBodyRef.current.scrollHeight,
      behaviour: "smooth",
    });
    resetInactivityTimeout();
  }, [chatHistory]);

  useEffect(() => {
    // Reset the inactivity timeout on user interaction with the chatbot
    const chatContainer = document.querySelector(".chatbot-popup");
    const resetTimeout = () => resetInactivityTimeout();

    chatContainer.addEventListener("mousemove", resetTimeout);
    chatContainer.addEventListener("keypress", resetTimeout);

    const handleBeforeUnload = async (event) => {
      await sendChatEmail();

      event.preventDefault();

      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      chatContainer.removeEventListener("mousemove", resetTimeout);
      chatContainer.removeEventListener("keypress", resetTimeout);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // Set the initial inactivity timeout when the component mounts
    resetInactivityTimeout();
  }, []);

  useEffect(() => {
    // Monitor user interactions to reset inactivity timeout
    const handleUserInteraction = () => {
      userInteractedRef.current = true;
      resetInactivityTimeout();
    };

    window.addEventListener("mousemove", handleUserInteraction);
    window.addEventListener("keypress", handleUserInteraction);

    return () => {
      window.removeEventListener("mousemove", handleUserInteraction);
      window.removeEventListener("keypress", handleUserInteraction);
    };
  }, []);

  return (
    <>
      <div className={`container ${showChatbot ? "show-chatbot" : ""}`}>
        <button
          onClick={() => {
            setShowChatbot((prev) => !prev);
          }}
          id="chatbot-toogler"
        >
          <span>
            <img src={chatbot} />
          </span>
          <span className="material-symbols-outlined">close</span>
        </button>
        <div className="chatbot-popup">
          {/* chatbot header*/}
          <div className="chat-header">
            <div className="header-info">
              <img className="chat" src={chatbot} alt="byteedge" />
              <h2 className="logo-text">chatbot</h2>
            </div>
            <button
              className="material-symbols-outlined"
              onClick={handleRefreshChat}
            >
              cached
            </button>
          </div>
          {/* chatbot body*/}
          <div ref={chatBodyRef} className="chatbot-body">
            <div className="message bot-message">
              <img className="chat" src={chatbot} alt="byteedge" />
              <p className="message-text">
                I am your AI buddy and can answer any question in natural human
                like language. Want to give me a try ?
              </p>
            </div>
            {chatHistory.map((message, index) => (
              <Chatmessage key={index} message={message} />
            ))}
          </div>
          <div className="ai-sugesstion">
            {suggestedQuestions.length > 0 && (
              <div className="ai-sugesstion">
                {suggestedQuestions.map((question, index) =>
                  !selectedQuestion ? (
                    <span
                      key={index}
                      onClick={() => handleQuestionClick(question)}
                    >
                      {question}
                    </span>
                  ) : null
                )}
              </div>
            )}
          </div>
          {/* chatbot footer*/}
          <div className="chat-footer">
            <Chatform
              chatHistory={chatHistory}
              setChatHistory={setChatHistory}
              generateBotResponse={generateBotResponse}
            />
          </div>
        </div>
      </div>
    </>
  );
}
